<template>
  <div class="Part" v-loading="loading">
    <div class="input">
      <el-row
        ><!--功能按钮-->
        <el-button
          type="primary"
          @click="addShifts"
          size="mini"
          v-if="
            authe.Hotel_Personnel_UserAttendance_AddAttendanceShift
          "
          >新增考勤班次</el-button
        >
        <el-button
          type="primary"
          @click="shiftsSetUp"
          size="mini"
          v-if="
            authe.Hotel_Personnel_UserAttendance_ModifyAttendanceShift
          "
          >修改考勤班次</el-button
        >
        <el-dialog
          :modal-append-to-body="false"
          
          :title="shiftsTitle"
          :visible.sync="dialogShifts"
          @close="closeDialogShifts"
          v-loading="loading"
          :close-on-click-modal="false"
          width="600px"
        >
          <el-form :model="shifts.params" ref="shiftForm" :rules="rules">
            <el-form-item label="班次名称" label-width="73px" prop="jssSpace"
              ><!--班次方案-->
              <el-input
                v-model="shifts.params.jssSpace"
                size="mini"
                style="width:25%"
              ></el-input>
            </el-form-item>

            <el-form-item label="班次1">
              &nbsp;&nbsp;&nbsp;
              <el-time-picker
                v-model="shifts.params.jssWork1"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="开始时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked1 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;至&nbsp;
              <el-time-picker
                v-model="shifts.params.jssOffwork1"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="结束时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked1 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;&nbsp;&nbsp; 是否启用:&nbsp;&nbsp;
              <el-checkbox v-model="shifts.checked1"></el-checkbox>
            </el-form-item>
            <el-form-item label="班次2">
              &nbsp;&nbsp;&nbsp;
              <el-time-picker
                v-model="shifts.params.jssWork2"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="开始时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked2 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;至&nbsp;
              <el-time-picker
                v-model="shifts.params.jssOffwork2"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="结束时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked2 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;&nbsp;&nbsp; 是否启用:&nbsp;&nbsp;
              <el-checkbox v-model="shifts.checked2"></el-checkbox>
            </el-form-item>
            <el-form-item label="班次3">
              &nbsp;&nbsp;&nbsp;
              <el-time-picker
                v-model="shifts.params.jssWork3"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="开始时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked3 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;至&nbsp;
              <el-time-picker
                v-model="shifts.params.jssOffwork3"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="结束时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked3 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;&nbsp;&nbsp; 是否启用:&nbsp;&nbsp;
              <el-checkbox v-model="shifts.checked3"></el-checkbox>
            </el-form-item>
            <el-form-item label="班次4">
              &nbsp;&nbsp;&nbsp;
              <el-time-picker
                v-model="shifts.params.jssWork4"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="开始时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked4 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;至&nbsp;
              <el-time-picker
                v-model="shifts.params.jssOffwork4"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                placeholder="结束时间"
                value-format="HH:mm:ss"
                :disabled="shifts.checked4 == false"
                clearable
                size="mini"
                style="width:125px"
              >
              </el-time-picker
              >&nbsp;&nbsp;&nbsp;&nbsp; 是否启用:&nbsp;&nbsp;
              <el-checkbox v-model="shifts.checked4"></el-checkbox>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogShifts = false">取 消</el-button>
            <!-- <el-button type="primary" v-if="this.state" @click="putShiftsSetUpSave">保 存</el-button> -->
            <el-button type="primary" @click="postAddShiftsSave"
              >保 存</el-button
            >
          </div>
        </el-dialog>

        <el-dialog
          :modal-append-to-body="false"  
          :title="shiftsTitle"
          :visible.sync="dialogModifyShifts"
          @close="closeDialogShifts"
          v-loading="loading"
          :close-on-click-modal="false"
          width="600px">
          <el-table border :data="dataShifts" height="419px">
            <el-table-column
              prop="jssSpace"
              label="班次"
              min-width="100px"
            ></el-table-column>
            <el-table-column label="编辑班次" min-width="100px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="modifyShiftsSetUp(scope.row)"
                  size="mini"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  @click="deleteShiftsSetUp(scope.row)"
                  size="mini"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer">
            <el-button @click="dialogModifyShifts = false">关 闭</el-button>
          </div>
        </el-dialog>

        <el-button
          class="btn"
          type="primary"
          @click="personnelClick"
          size="mini"
          v-if="
            authe.Hotel_Personnel_UserAttendance_AttendanceShiftStaffing
          "
          >设置考勤班次人员</el-button
        >

        <el-dialog
          :modal-append-to-body="false"
          
          title="考勤班次人员设置"
          :visible.sync="dialogPersonnelSetUp"
          v-loading="loading"
          :close-on-click-modal="false"
          width="500px"
        >
          考勤班次:
          <el-select v-model="jssId" clearable size="mini" style="width:150px">
            <el-option
              v-for="(value, i) in dataShifts"
              :key="i"
              :label="value.jssSpace"
              :value="value.jssId"
            ></el-option>
          </el-select>
          <div slot="footer">
            <el-button @click="dialogPersonnelSetUp = false">取 消</el-button>
            <el-button type="primary" @click="putPersonnelSetUpSave"
              >保 存</el-button
            >
          </div>
        </el-dialog>
        <!--今日考勤 -->
        <el-button type="primary" @click="todayClick" size="mini"
          >今日考勤</el-button
        >
        <el-dialog
          :modal-append-to-body="false"
          
          v-loading="loading"
          :visible.sync="dialogToday"
          :close-on-click-modal="false"
          width="1000px"
          title="今日考勤"
        >
          <el-row style="padding: 5px 5px 5px 0;"
            ><!--按条件查询考勤记录-->
            姓名：<el-cascader
              @change="getAttendanceToday(1)"
              v-model="todayRecords.userCoding"
              :options="userNameList"
              filterable
              clearable
              :props="{ checkStrictly: true, expandTrigger: 'hover' }"
              size="mini"
              style="width:200px"
            ></el-cascader
            >&nbsp;&nbsp; 考勤班次:
            <el-select
              v-model="todayRecords.params.jssId"
              @change="getAttendanceToday(1)"
              clearable
              size="mini"
              style="width:150px"
            >
              <el-option
                v-for="(value, i) in dataShifts"
                :key="i"
                :label="value.jssSpace"
                :value="value.jssId"
              ></el-option>
            </el-select>
          </el-row>
          <el-table border :data="todayData.records" height="450px">
            <el-table-column
              align="center"
              prop="jssSpace"
              label="班次名"
              min-width="70px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="storefrontName"
              label="门店"
              min-width="70px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="部门"
              min-width="70px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="suStaffName"
              label="姓名"
              min-width="80px"
            ></el-table-column>
            <el-table-column
              prop="jarWork1"
              v-if="dataListState.jssStatus1 == 1"
              label="上班1"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarOffwork1"
              v-if="dataListState.jssStatus1 == 1"
              label="下班1"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarWork2"
              v-if="dataListState.jssStatus2 == 1"
              label="上班2"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarOffwork2"
              v-if="dataListState.jssStatus2 == 1"
              label="下班2"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarWork3"
              v-if="dataListState.jssStatus3 == 1"
              label="上班3"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarOffwork3"
              v-if="dataListState.jssStatus3 == 1"
              label="下班3"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarWork4"
              v-if="dataListState.jssStatus4 == 1"
              label="上班4"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="jarOffwork4"
              v-if="dataListState.jssStatus4 == 1"
              label="下班4"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="hsId"
              label="加班"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="hsId"
              label="下班"
              align="center"
              min-width="100px"
            ></el-table-column>
          </el-table>
          <el-pagination
            @size-change="todayHandleSizeChange"
            @current-change="todayHandleCurrentChange"
            :current-page="todayRecords.params.cursor"
            :page-count="todayRecords.params.limit"
            :page-sizes="[5, 10, 20, 40]"
            :page-size="todayData.size"
            size="mini"
            align="center"
            layout="total, sizes, prev, pager, next, jumper"
            :total="todayData.total"
          >
          </el-pagination>
        </el-dialog>
      </el-row>

      <el-row style="padding: 5px 5px 5px 0;"
        ><!--按条件查询考勤记录-->
        姓名：<el-input
          v-model="signInRecords.params.suStaffName"
          @change="getData"
          clearable
          size="mini"
          style="width:150px"
        ></el-input
        >&nbsp;&nbsp; 考勤班次:
        <el-select
          v-model="signInRecords.params.jssSpace"
          @change="getData"
          clearable
          size="mini"
          style="width:150px"
        >
          <el-option
            v-for="(value, i) in dataShifts"
            :key="i"
            :label="value.jssSpace"
            :value="value.jssSpace"
          ></el-option>
        </el-select>
      </el-row>
    </div>
    <el-table
      border
      :data="dataList"
      @selection-change="selTable"
      @row-dblclick="homeDbclick"
      :height="tableHeight"
    >
      <el-table-column type="selection" width="45px"></el-table-column>
      <el-table-column
        align="center"
        prop="jssSpace"
        label="班次名"
        min-width="70px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="storefrontName"
        label="门店"
        min-width="70px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="departmentName"
        label="部门"
        min-width="70px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="suStaffName"
        label="姓名"
        min-width="80px"
      ></el-table-column>
    </el-table>
    <el-dialog
      :modal-append-to-body="false"
  
      title="考勤记录"
      :visible.sync="dialogViewDetails2"
      v-loading="loading"
      :close-on-click-modal="false"
      width="1000px"
      ><!--考勤记录详情-->
      姓名：<el-input
        :value="homeRow.suStaffName"
        size="mini"
        style="width:15%"
        disabled
      ></el-input
      >&nbsp;&nbsp;
      <el-date-picker
        v-model="month"
        type="month"
        placeholder="选择月"
        size="mini"
        :clearable="false"
      >
      </el-date-picker>
      <el-calendar v-model="month">
        <template
          slot="dateCell"
          slot-scope="{ date, data }"
          style="padding:0;margin:0"
        >
          <!-- <div @click="getViewDetails(data.day)" :class="[(dayList2.includes(data.day) && (data.day+'')>=(nowTime+''))?'green':'',(dayList2.includes(data.day) || (data.day+'')>=(nowTime+''))?'':'red','box']" style="text-align:center;"> -->
          <div
            @click="getViewDetails(data)"
            class="box"
            style="text-align:center;"
          >
            <el-badge
              value="正"
              style="float:left"
              type="success"
              v-show="nowTime + '' > data.day + ''"
              v-if="dayList2.includes(data.day)"
            ></el-badge>
            <el-badge
              value="异"
              style="float:left"
              v-show="nowTime + '' > data.day + ''"
              v-else-if="dayList3.includes(data.day)"
            ></el-badge>
            <el-badge
              value="迟"
              style="float:left"
              type="warning"
              v-show="nowTime + '' > data.day + ''"
              v-else-if="dayList4.includes(data.day)"
            ></el-badge>
            <el-badge
              value="事"
              style="float:left"
              type="warning"
              v-show="nowTime + '' > data.day + ''"
              v-else-if="dayList5.includes(data.day)"
            ></el-badge>
            <el-badge
              value="病"
              style="float:left"
              type="warning"
              v-show="nowTime + '' > data.day + ''"
              v-else-if="dayList6.includes(data.day)"
            ></el-badge>
            <!-- <el-badge value="旷" style="float:left" type="warning" v-show="(nowTime+'')>(data.day+'')" v-if="dayList7.includes(data.day)"></el-badge> -->
            <el-badge
              value="缺"
              style="float:left"
              type="info"
              v-show="data.type === 'current-month'&&nowTime + '' > data.day + ''"
              v-else
            ></el-badge>
            {{ data.day.split("-")[2] }}
          </div>
        </template>
      </el-calendar>
    </el-dialog>
    <el-dialog
      :modal-append-to-body="false"
      
      title="查看详情"
      :visible.sync="dialogViewDetails"
      v-loading="loading"
      append-to-body
      :close-on-click-modal="false"
      width="1000px"
      ><!--考勤记录详情-->
      <el-form style="text-align: center">
        <el-form-item>
          <div style="padding-left: 20px">
            <span
              >区域：<el-input
                :value="details.storefrontName"
                size="mini"
                style="width:15%"
                disabled
              ></el-input
              >&nbsp;&nbsp;</span
            >
            <span
              >部门：<el-input
                :value="details.departmentName"
                size="mini"
                style="width:15%"
                disabled
              ></el-input
              >&nbsp;&nbsp;</span
            >
            <span
              >姓名：<el-input
                :value="details.suStaffName"
                size="mini"
                style="width:15%"
                disabled
              ></el-input
              >&nbsp;&nbsp;</span
            >
            <span
              >日期：<el-input
                :value="details.jarWorkTime"
                size="mini"
                style="width:18%"
                disabled
              ></el-input
              >&nbsp;&nbsp;</span
            >
            <span
              ><el-button
                @click="getSearchRecord"
                size="mini"
                v-if="
                  authe.House_Personnel_AttendanceuserManagement_IdentificationRecord
                "
                type="primary"
                >识别记录</el-button
              ></span
            >
          </div>
        </el-form-item>
        <el-form-item
          ><!--数据表-->
          <el-row style="padding: 0 30px">
            <el-col :span="3">
              <el-table border :data="dialogColTitle" height="306px" size="mini"
                ><!--所有教师表-->
                <el-table-column
                  prop="title"
                  label="所属班次"
                  align="center"
                  min-width="100px"
                ></el-table-column>
              </el-table>
            </el-col>
            <el-col :span="21">
              <el-table
                border
                :data="detailList"
                height="306px"
                style="padding: 0"
                size="mini"
                ><!--所有班次表-->
                <el-table-column
                  v-if="scheduleRow.jssStatus1 == 1"
                  prop="jarWork1"
                  label="上班1"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus1 == 1"
                  prop="jarOffwork1"
                  label="下班1"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus2 == 1"
                  prop="jarWork2"
                  label="上班2"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus2 == 1"
                  prop="jarOffwork2"
                  label="下班2"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus3 == 1"
                  prop="jarWork3"
                  label="上班3"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus3 == 1"
                  prop="jarOffwork3"
                  label="下班3"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus4 == 1"
                  prop="jarWork4"
                  label="上班4"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  v-if="scheduleRow.jssStatus4 == 1"
                  prop="jarOffwork4"
                  label="下班4"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  prop="jarOvertime"
                  label="加班"
                  align="center"
                  min-width="50px"
                ></el-table-column>
                <el-table-column
                  prop="jarOvertimeOffwork"
                  label="加班下班"
                  align="center"
                  min-width="50px"
                ></el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row style="padding: 10px 30px">
            考勤状态：<el-select
              v-model="jarState"
              size="mini"
              style="width:160px"
            >
              <el-option :label="jarState2" :value="jarState2"></el-option>
              <el-option
                label="正常"
                value="正常"
                v-show="jarState2 != '正常'"
              ></el-option>
            </el-select>
            考勤备注：<el-input
              type="textarea"
              v-model="jarRemark"
              size="mini"
              style="width:500px"
            ></el-input>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogViewDetails = false">取 消</el-button>
        <el-button
          type="primary"
          @click="putCheckWorkRemarks"
          v-if="authe.House_Personnel_AttendanceuserManagement_ViewDetailsSave"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :modal-append-to-body="false"
     
      title="补卡"
      :visible.sync="dialogCardSupplement"
      v-loading="loading"
      append-to-body
      :close-on-click-modal="false"
      width="420px"
      ><!--考勤记录详情-->
      <el-form label-width="70px">
        <el-form-item label="日期">
          <el-input
            :value="checkDay"
            size="mini"
            style="width:300px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="考勤状态">
          <el-select v-model="jarState" size="mini" style="width:300px">
            <el-option label="病假" value="病假"></el-option>
            <el-option label="事假" value="事假"></el-option>
            <el-option label="正常" value="正常"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考勤备注">
          <el-input
            type="textarea"
            v-model="jarRemark"
            size="mini"
            style="width:300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogCardSupplement = false">取 消</el-button>
        <el-button type="primary" @click="postAbsenceCard">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :modal-append-to-body="false"
     
      title="识别记录"
      :visible.sync="dialogSearchRecord"
      @close="cleanSearchRecord"
      v-loading="loading"
      :close-on-click-modal="false"
      width="1000px"
      ><!--考勤记录详情-->
      <el-row>
        姓名：<el-input
          :value="faceRecord.jftiPersonName"
          size="mini"
          style="width:15%"
          disabled
        ></el-input
        >&nbsp;&nbsp; 日期筛选：<el-date-picker
          v-model="faceRecord.timeSearch"
          @change="recordTimeChange"
          :unlink-panels="true"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          size="mini"
        ></el-date-picker>
      </el-row>
      <el-table border :data="dataFaceRecord">
        <el-table-column
          prop="jftiPasernType"
          label="人员类型"
          align="center"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="jftiPersonName"
          label="人员"
          align="center"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="hsAddCommunity"
          label="设备地址"
          align="center"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="jftiRecMode"
          label="识别类型"
          align="center"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="jfriShowTime"
          label="识别记录时间"
          align="center"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="jfriAliveType"
          label="识别结果"
          align="center"
          min-width="100px"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :page-count="faceRecord.pages"
        :page-size="faceRecord.params.limit"
        :total="faceRecord.total"
        :current-page="faceRecord.params.cursor"
        @current-change="recordCurrentChange"
        @size-change="recordSizeChange"
        :page-sizes="[5, 10, 20, 40]"
        size="mini"
        align="center"
      ></el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import controlSetUpVue from "../equipmentMatters/controlSetUp.vue";

export default {
  name: "attendance",

  data() {
    return {
      rules: {
        //验证规则
        jssSpace: [
          { required: true, message: "班次名称不能为空", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "班次名称长度为 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      dialogViewDetails2: false,
      loading: false,
      indexTest: 9999,
      shiftsTitle: "修改考勤班次",
      state: true,
      tableHeight: window.innerHeight - 225,
      dialogShifts: false,
      dialogModifyShifts: false,
      dialogPersonnelSetUp: false,
      dialogViewDetails: false,
      dialogSearchRecord: false,
      dialogCardSupplement: false,
      dialogToday: false,
      checkDay: "",
      shifts: {
        //考勤设置
        params: {
          jssSpace: "",

          jssWork1: "",
          jssOffwork1: "",
          jssStatus1: 0,

          jssWork2: "",
          jssOffwork2: "",
          jssStatus2: 0,

          jssWork3: "",
          jssOffwork3: "",
          jssStatus3: 0,

          jssWork4: "",
          jssOffwork4: "",
          jssStatus4: 0,
        },
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        jssSpace: "",
        jssId: "",
      },
      personnel: {
        //人员相关
        params: {
          suStaffName: "",
          jssSpace: "",
        },
        jssId: "",
        key: 0,
      },
      signInRecords: {
        //考勤记录
        params: {
          suStaffName: "",
          jssSpace: "",
        },
        nameSearch: [],
        timeSearch: [],
        total: 0, //总条数
        pages: 0, //每页数据
      },
      todayRecords: {
        //今日考勤
        params: {
          jssId: "",
          limit: 10,
          cursor: 1,
        },
        userCoding: "",
      },
      todayData: [],
      faceRecord: {
        //识别记录
        params: {
          jftiUserId: "",
          startTime: "",
          endTime: "",
          limit: 10,
          cursor: 1,
        },
        jftiPersonName: "",
        timeSearch: "",
        pages: 0,
        total: 0,
      },
      dataList: [], //考勤记录数据
      dataListState: {
        //根据班次信息显示列表上下班列
        jssStatus1: "",
        jssStatus2: "",
        jssStatus3: "",
        jssStatus4: "",
      },
      dataName: [], //姓名下拉数据
      dataShifts: [], //班次下拉数据
      dataPersonnel: [], //查询所有教师
      dataFaceRecord: [], //人脸识别记录数据
      dialogColTitle: [
        //考勤详情表格行标题
        { title: "打卡时间" },
        { title: "电脑名称" },
        { title: "MAC地址" },
        { title: "电脑标识" },
        { title: "外网IP" },
      ],
      time: moment().format("YYYY-MM"),
      dayList: [],
      dialogViewDetails: false,
      dayList2: [],
      dayList3: [],
      dayList4: [],
      dayList5: [],
      dayList6: [],
      dayList7: [],
      month: new Date(),
      jarRemark: "",
      jarState: "",
      jarState2: "",
      jarId: "",
      nowTime: moment().format("YYYY-MM-DD"),
      details: {},
      detailList: [],
      selList: [],
      jssId: "",
      homeRow: {},
      scheduleRow: {},
    };
  },
  computed: {
    ...mapState(["userNameList", "authe", "pageAuth"]),
  },
  watch: {
    month(val) {
      let m = moment(val).format("YYYY-MM");
      if (m + "" == this.time + "") {
        return;
      }
      this.time = m;
      this.getAttendanceRecords();
    },
    'dialogModifyShifts':function(n,o){
      if(n==false){
        this.getData()
        // console.log(this.todayData)
      }
    }
  },
  created() {
    this.getData()
    this.getDataShifts()
  },

  methods: {
    //表单验证
    submitForm(formName) {
      let state = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          state = true;
        } else {
          state = false;
        }
      });
      return state;
    },
    resetForm(formName) {
      setTimeout(() => {
        // this.$refs[formName].resetFields();//清除name
        this.$refs[formName].clearValidate(); //不清除name
      }, 1);
    },
    //考勤记录查询相关
    getData(t) {
      //获取考勤记录
      console.log(t, this.signInRecords.params, 123123123);
      this.$axios({
        method: "get",
        url: "/common1/shiftSchedules/users",
        params: this.signInRecords.params,
      })
        .then((res) => {
          this.dataList = res.data.result.records;
          this.signInRecords.total = res.data.result.total;
        })
        .catch((err) => {
          this.isError(err);
        });
    },

    getDataShifts() {
      //获取所有班次
      this.$axios({
        method: "get",
        url: "/common1/shiftSchedules",
      })
        .then((res) => {
          if(res.data.result!=undefined){
            this.dataShifts = res.data.result;
            // this.signInRecords.params.jssSpace = this.dataShifts[0].jssSpace;
          }
        })
        .catch((err) => {
          this.isError(err);
        });
    },
    //考勤设置相关
    addShifts() {
      //添加班次赋值
      this.state = false;
      this.shiftsTitle = "新增考勤班次";
      this.dialogShifts = true;
      this.resetForm("shiftForm");
    },
    //今日考勤
    todayClick() {
      //今日考勤按钮
      this.todayRecords.params.jssId = this.dataShifts[0].jssId;
      this.getShiftSchedules(); // 获取班次
      this.getAttendanceToday(1);
      this.dialogToday = true;
    },
    getAttendanceToday(t) {
      //分页查询今日考勤
      if (t == 1) {
        this.todayRecords.params.cursor = 1;
      }
      this.$axios({
        method: "get",
        url: "/common1/attendanceRecords",
        params: {
          storefrontId:
            this.todayRecords.userCoding.length > 0
              ? this.todayRecords.userCoding[0]
              : "",
          departmentId:
            this.todayRecords.userCoding.length > 1
              ? this.todayRecords.userCoding[1]
              : "",
          userCoding:
            this.todayRecords.userCoding.length > 2
              ? this.todayRecords.userCoding[2]
              : "",
          limit: this.todayRecords.params.limit,
          cursor: this.todayRecords.params.cursor,
          jssId: this.todayRecords.params.jssId,
          startJarWorkTime: moment().format("YYYY-MM-DD"),
          endJarWorkTime: moment().format("YYYY-MM-DD"),
        },
      })
        .then((res) => {
          this.todayData = res.data.result;
        })
        .catch((err) => {
          this.isError(err);
        });
    },
    todayHandleSizeChange(limit) {
      //点击更改显示条数
      this.todayRecords.params.limit = limit;
      this.getAttendanceToday(1);
    },
    todayHandleCurrentChange(cursor) {
      //点击更改点前页数
      this.todayRecords.params.cursor = cursor;
      this.getAttendanceToday();
    },
    getShiftSchedules() {
      // 获取班次
      this.$axios({
        method: "get",
        url: `/common1/shiftSchedules/${this.todayRecords.params.jssId}`,
      })
        .then((res) => {
          console.log(res.data.result);
          this.dataListState = res.data.result;
        })
        .catch((err) => {
          this.isError(err);
        });
    },

    postAddShiftsSave() {
      //添加班次提交
      if (
        this.shifts.checked1 &&
        (!this.shifts.params.jssWork1 || !this.shifts.params.jssOffwork1)
      ) {
        this.$message.warning("班次1输入不完整");
        return;
      }
      if (
        this.shifts.checked2 &&
        (!this.shifts.params.jssWork2 || !this.shifts.params.jssOffwork2)
      ) {
        this.$message.warning("班次2输入不完整");
        return;
      }
      if (
        this.shifts.checked3 &&
        (!this.shifts.params.jssWork3 || !this.shifts.params.jssOffwork3)
      ) {
        this.$message.warning("班次3输入不完整");
        return;
      }
      if (
        this.shifts.checked4 &&
        (!this.shifts.params.jssWork4 || !this.shifts.params.jssOffwork4)
      ) {
        this.$message.warning("班次4输入不完整");
        return;
      }
      if (!this.submitForm("shiftForm")) {
        return;
      }
      if (this.shifts.checked2) {
        let start2 = this.shifts.params.jssWork2.replace(/\:/g, "");
        if (this.shifts.checked1) {
          let end1 = this.shifts.params.jssOffwork1.replace(/\:/g, "");
          if (start2 <= end1) {
            this.$message.warning("班次2上班时间必须大于上一个下班时间");
            return;
          }
        }
      }
      if (this.shifts.checked3) {
        let start3 = this.shifts.params.jssWork3.replace(/\:/g, "");
        if (this.shifts.checked2) {
          let end2 = this.shifts.params.jssOffwork2.replace(/\:/g, "");
          if (start3 <= end2) {
            this.$message.warning("班次3上班时间必须大于上一个下班时间");
            return;
          }
        } else if (this.shifts.checked1) {
          let end1 = this.shifts.params.jssOffwork1.replace(/\:/g, "");
          if (start3 <= end1) {
            this.$message.warning("班次3上班时间必须大于上一个下班时间");
            return;
          }
        }
      }
      if (this.shifts.checked4) {
        let start4 = this.shifts.params.jssWork4.replace(/\:/g, "");
        if (this.shifts.checked3) {
          let end3 = this.shifts.params.jssOffwork3.replace(/\:/g, "");
          if (start4 <= end3) {
            this.$message.warning("班次4上班时间必须大于上一个下班时间");
            return;
          }
        } else if (this.shifts.checked2) {
          let end2 = this.shifts.params.jssOffwork2.replace(/\:/g, "");
          if (start4 <= end2) {
            this.$message.warning("班次4上班时间必须大于上一个下班时间");
            return;
          }
        } else if (this.shifts.checked1) {
          let end1 = this.shifts.params.jssOffwork1.replace(/\:/g, "");
          if (start4 <= end1) {
            this.$message.warning("班次4上班时间必须大于上一个下班时间");
            return;
          }
        }
      }
      this.shifts.params.jssStatus1 = this.shifts.checked1 ? "1" : "0";
      this.shifts.params.jssStatus2 = this.shifts.checked2 ? "1" : "0";
      this.shifts.params.jssStatus3 = this.shifts.checked3 ? "1" : "0";
      this.shifts.params.jssStatus4 = this.shifts.checked4 ? "1" : "0";
      if (this.shiftsTitle == "新增考勤班次") {
        //新增班次
        this.$axios({
          method: "post",
          url: "/common1/shiftSchedules",
          data: this.shifts.params,
        })
          .then((res) => {
            this.loading = false;
            this.getDataShifts();
            this.$message.success("添加成功");
            this.dialogShifts = false;
          })
          .catch((err) => {
            this.loading = false;
            this.isError(err);
          });
      } else {
        //修改班次
        this.$axios({
          method: "put",
          url: `/common1/shiftSchedules/${this.shifts.jssId}`,
          data: this.shifts.params,
        })
          .then((res) => {
            this.loading = false;
            this.$message.success("修改成功");
            this.getDataShifts();
            this.dialogShifts = false;
          })
          .catch((err) => {
            this.loading = false;
            this.isError(err);
          });
      }
    },
    shiftsSetUp() {
      //修改班次列表
      this.shiftsTitle = "修改考勤班次";
      this.dialogModifyShifts = true;
    },
    modifyShiftsSetUp(row) {
      //修改班次赋值
      this.state = true;
      this.shifts.jssId = row.jssId;
      this.shifts.jssSpace = row.jssSpace;
      this.$axios({
        method: "get",
        url: `/common1/shiftSchedules/${this.shifts.jssId}`,
      })
        .then((res) => {
          for (let i in this.shifts.params) {
            for (let j in res.data.result) {
              if (i == j) {
                this.shifts.params[i] = res.data.result[j];
              }
            }
          }
          this.shifts.checked1 = Boolean(Number(this.shifts.params.jssStatus1));
          this.shifts.checked2 = Boolean(Number(this.shifts.params.jssStatus2));
          this.shifts.checked3 = Boolean(Number(this.shifts.params.jssStatus3));
          this.shifts.checked4 = Boolean(Number(this.shifts.params.jssStatus4));
          this.resetForm("shiftForm");
          this.dialogShifts = true;
 
        })
        .catch((err) => {
          this.isError(err);
        });
    },

    deleteShiftsSetUp(row) {
      //删除班次
      this.loading = true;
      this.$axios({
        method: "delete",
        url: `/common1/shiftSchedules/${row.jssId}`,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("删除成功");
          this.getDataShifts();
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err);
        });
    },
    closeDialogShifts() {
      //关闭时清空
      this.shifts = {
        //考勤设置
        params: {
          jssSpace: "",

          jssWork1: "",
          jssOffwork1: "",
          jssStatus1: 0,

          jssWork2: "",
          jssOffwork2: "",
          jssStatus2: 0,

          jssWork3: "",
          jssOffwork3: "",
          jssStatus3: 0,

          jssWork4: "",
          jssOffwork4: "",
          jssStatus4: 0,
        },
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        jssSpace: "",
        jssId: "",
      };
      this.getData()
    },

    //考勤班次人员设置相关
    selTable(sel) {
      this.selList = sel;
    },
    personnelClick() {
      if (!this.selList || this.selList.length == 0) {
        this.$message.warning("请勾选需要修改班次的人员");
        return;
      }
      this.dialogPersonnelSetUp = true;
    },
    putPersonnelSetUpSave() {
      //人员设置提交
      this.loading = true;
      let arr = [];
      for (let i of this.selList) {
        let obj = {
          jssId: this.jssId,
          userCoding: i.userCoding,
        };
        arr.push(obj);
      }
      this.$axios({
        method: "put",
        url: `/common1/shiftSchedules/users/shiftSchedules`,
        data: arr,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("修改成功");
          this.dialogPersonnelSetUp = false;
          this.getData(1);
          this.jssId = "";
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err);
        });
    },

    //考勤详情相关
    homeDbclick(row) {
      //表格双击获取班次考勤记录
      this.homeRow = row;
      this.month = new Date();
      if(this.homeRow.hasOwnProperty('jssId')){
        this.dialogViewDetails2 = true;
        this.getAttendanceRecords();
        this.getSchedules();
      }else{
        this.$message({
          message: '请先设置考勤班次！',
          type: 'warning'
        });
      }
    },
    getSchedules() {
      this.$axios({
        method: "get",
        url: `/common1/shiftSchedules/${this.homeRow.jssId}`,
      })
        .then((res) => {
          this.scheduleRow = res.data.result;
        })
        .catch((err) => {
          this.isError(err);
        });
    },
    getAttendanceRecords() {
      //按月份查考勤记录
      this.$axios({
        method: "get",
        url: "/common1/attendanceRecords",
        params: {
          cursor: 1,
          limit: 31,
          startJarWorkTime: moment(this.month).format("YYYY-MM-01"),
          endJarWorkTime: moment(this.month)
            .endOf("month")
            .format("YYYY-MM-DD"),
          userCoding: this.homeRow.userCoding,
        },
      }).then((res) => {
        this.dayList2 = [];
        this.dayList3 = [];
        this.dayList4 = [];
        this.dayList5 = [];
        this.dayList6 = [];
        this.dayList = [];
        res.data.result.records.forEach((item) => {
          if (item.jarId) {
            let time = moment(item.jarWorkTime).format("YYYY-MM-DD");
            this.dayList.push({ time: time, jarId: item.jarId });
            console.log(item.jarState, 1);
            if (item.jarState == "正常") {
              this.dayList2.push(time);
            } else if (item.jarState == "异常") {
              this.dayList3.push(time);
            } else if (item.jarState == "迟到") {
              this.dayList4.push(time);
            } else if (item.jarState == "事假") {
              this.dayList5.push(time);
            } else if (item.jarState == "病假") {
              this.dayList6.push(time);
            }
          }
        });
      });
    },
    getViewDetails(data) {
      console.log(data)
      if(data.type !== 'current-month') {
        return
      }
      //获取班次考勤记录
      let day = data.day
      let now = moment().format("YYYY-MM-DD");
      if (day + "" > now + "") {
        return;
      }
      let obj = this.dayList.find((item) => {
        return item.time + "" == day + "";
      });
      console.log(obj)
      if (!obj) {
        this.checkDay = day;
        this.dialogCardSupplement = true;
        return;
      }
      this.jarId = obj.jarId;
      this.dialogViewDetails = true;
      this.$axios({
        method: "get",
        url: `/common1/attendanceRecords/${obj.jarId}`,
      })
        .then((res) => {
          this.details = res.data.result.records
            ? res.data.result.records[0]
            : {};
          this.detailList = res.data.result.records;
          this.jarRemark = res.data.result.records
            ? res.data.result.records[0].jarSpare
            : "";
          this.jarState = res.data.result.records
            ? res.data.result.records[0].jarState
            : "";
          this.jarState2 = res.data.result.records
            ? res.data.result.records[0].jarState
            : "";
        })
        .catch((err) => {
          this.isError(err);
        });
    },
    putCheckWorkRemarks() {
      //修改考勤备注
      this.loading = true;
      this.$axios({
        method: "put",
        url: `/common1/attendanceRecords/${this.jarId}`,
        params: {
          jarSpare: this.jarRemark,
          jarState: this.jarState,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$message.success(`修改成功`);
          this.dialogViewDetails = false;
          this.getAttendanceRecords();
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err);
        });
    },
    postAbsenceCard() {
      //补卡保存按钮
      if (!this.jarRemark) {
        this.$message.warning("考勤备注不能为空");
        return;
      }
      if (!this.jarState) {
        this.$message.warning("考勤状态不能为空");
        return;
      }
      this.loading = true;
      this.$axios({
        method: "post",
        url: "/common1/attendanceRecords/absenceCard",
        params: {
          spare: this.jarRemark,
          localDate: moment(this.checkDay).format("YYYY-MM-DD"),
          state: this.jarState,
          userCoding: this.homeRow.userCoding,
        },
      })
        .then((res) => {
          this.loading = false;
          this.dialogCardSupplement = false;
          this.$message.success("补卡成功");
          this.getAttendanceRecords();
          this.jarRemark = "";
          this.jarState = "";
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err);
        });
    },
    getSearchRecord() {
      //查询识别记录
      this.faceRecord.params.jftiUserId = this.homeRow.userCoding;
      this.$axios({
        method: "get",
        url: "/common2/faceRecognitionInformations",
        params: this.faceRecord.params,
      })
        .then((res) => {
          this.dataFaceRecord = res.data.result.records;
          this.faceRecord.total = res.data.result.total;
          this.dialogSearchRecord = true;
        })
        .catch((err) => {
          this.isError(err);
        });
    },
    recordTimeChange() {
      //日期改变时查询
      if (!this.faceRecord.timeSearch) {
        this.faceRecord.params.startTime = "";
        this.faceRecord.params.endTime = "";
        this.faceRecord.params.cursor = 1;
        this.getSearchRecord();
      } else {
        this.faceRecord.params.startTime = this.faceRecord.timeSearch[0];
        this.faceRecord.params.endTime = this.faceRecord.timeSearch[1];
        this.faceRecord.params.cursor = 1;
        this.getSearchRecord();
      }
    },
    recordSizeChange(limit) {
      //点击更改显示条数
      this.faceRecord.params.limit = limit;
      this.getSearchRecord();
    },
    recordCurrentChange(cursor) {
      //点击更改点前页数
      this.faceRecord.params.cursor = cursor;
      this.getSearchRecord();
    },
    cleanSearchRecord() {
      //关闭时清空识别记录
      this.faceRecord = {
        params: {
          jftiUserId: "",
          startTime: "",
          endTime: "",
          limit: 10,
          cursor: 1,
        },
        jftiPersonName: "",
        timeSearch: "",
        pages: 0,
        total: 0,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.btn{
  margin:0 8px
}
.cell-selection .cell span .el-icon-arrow-down {
  display: none;
}
.cell-selection .cell span:hover {
  background: #ccc;
  padding: 10px;
  .el-icon-arrow-down {
    display: inline;
  }
}
.box {
  height: 100%;
  width: 100%;
  margin: -8px 0 0 -8px;
  padding: 8px;
}
.green {
  // color:#fff;
  color: green;
}
.red {
  color: red;
  // background:rgba(248, 129, 113, 0.829)
}
</style>
